@import '../../../../scss/theme-bootstrap';

.utility-banner-formatter {
  &__close {
    &-icon {
      background-image: url('https://www.bobbibrowncosmetics.ca/media/export/cms/global/bb-icon-x-white_2.png');
      background-size: contain;
      width: 12px;
      height: 12px;
      position: absolute;
      #{$rdirection}: 0;
      top: 19px;
      cursor: pointer;
    }
  }
}
